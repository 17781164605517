/* Target the scrollbar element */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: transparent;
  border-radius: 1rem;
}

/* Target the thumb */
::-webkit-scrollbar-thumb {
  background-color: #353539;
  border-radius: 1rem;
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
